import React from "react";
import "./TalkCard.scss";

export default function TalkCard({talkDetails}) {
  return (
    <div className="container">
      <div className={`rectangle ${talkDetails.isDark ? 'dark-rectangle' : ''}`}>
        <div className="talk-card-content">
          <div className="talk-card-title">{talkDetails.title}</div>
          <p className="talk-card-subtitle">{talkDetails.subtitle}</p>

          <img 
            src={talkDetails.image} 
            alt="talk" 
            className="talk-card-image" 
          />
          <div className="card-footer-button-div">
            <a href={talkDetails.slides_url} target="_blank" rel="noopener noreferrer" className="talk-button">
              Slides
            </a>
            <a href={talkDetails.event_url} target="_blank" rel="noopener noreferrer" className="talk-button">
              Event
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}