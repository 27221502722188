import React from "react";
import "./SoftwareSkill.scss";
import {skillsSection} from "../../portfolio";

export default function SoftwareSkill() {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((icon, index) => (
                    <img key={index} className=" px-2 " alt="Some Skill" src={`https://go-skill-icons.vercel.app/api/icons?i=${icon}&perline=5`} />
          ))}
        </ul>
      </div>
    </div>
  );
}
